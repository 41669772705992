import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MarkdownComponents from '../utils/MarkdownComponents';

import './MainPage.css';

import hello from '../content/hello-world';
import diary from '../content/diary';
const content = [
    hello,
    diary,
];

export default function MainPage() {
    return (
        content.map((post)=>{
            return <div className="post">
                <ReactMarkdown remarkPlugins={[remarkGfm]} components={MarkdownComponents}>
                    {post}
                </ReactMarkdown>
            </div>
        })
    );
};