import generateSlug from '../utils/generateSlug';

function generateHeading(arr) {
    let heading = '';

    for (let i = 0; i < arr.length; i++) {
        if (arr[i]?.type !== undefined) {
            for (let j = 0; j < arr[i].props.children.length; j++) {
                heading += arr[i]?.props?.children[0];
            }
        } else heading += arr[i];
    }
    return heading;
}

const headingStyle = {
    color: '#000', textDecoration: 'none'
};

const MarkdownComponents = {
    h1: (props) => {
        const heading = generateHeading(props.children);
        const slug = generateSlug(heading);
        return <h1 id={slug}><a href={`#${slug}`} {...props} style={headingStyle}></a></h1>
    },
    h2: (props) => {
        const heading = generateHeading(props.children);
        const slug = generateSlug(heading);
        return <h2 id={slug}><a href={`#${slug}`} {...props} style={headingStyle}></a></h2>
    }
}

export default MarkdownComponents;